/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        $('.wpcf7-submit').on('click', function() {
          $(this).addClass('loading');
        });
        $('.wpcf7-submit').on('click', function() {
          $(this).addClass('loading');
        });

        $('.wpcf7').on(
          'wpcf7invalid wpcf7spam wpcf7mailsent wpcf7mailfailed',
          function() {
            $(this)
              .find('.wpcf7-submit')
              .removeClass('loading');
          },
        );
        //bootstrap accordeon init
        $('.collapse').collapse({
          toggle: false,
        });

        $('.alert').alert();

        //bootstrap scroll to opened accordion
        $('.collapse').on('shown.bs.collapse', function() {
          var $panel = $(this).closest('.card');
          $('html,body').animate(
            {
              scrollTop: $panel.offset().top,
            },
            500,
          );
        });

        if ({}.hasOwnProperty.call(window, 'cookieconsent')) {
          window.cookieconsent.initialise({
            palette: {
              popup: {
                background: '#ffffff',
              },
              button: {
                background: '#00c7e3',
              },
            },
            content: {
              href: cookieConsent.href,
              message: cookieConsent.disclaimer,
              dismiss: cookieConsent.allow,
              link: cookieConsent.learn,
            },
            position: 'bottom-left',
          });
        }

        //defer youtube loading
        function vidDefer() {
          var vidDefer = document.getElementsByTagName('iframe');
          for (var i = 0; i < vidDefer.length; i++) {
            if (vidDefer[i].getAttribute('data-src')) {
              vidDefer[i].setAttribute(
                'src',
                vidDefer[i].getAttribute('data-src'),
              );
            }
          }
        }
        window.onload = vidDefer;

        const rgpd = document.querySelector('.rgpd-settings');
        rgpd &&
          rgpd.addEventListener('click', e => {
            e.preventDefault();
            klaro.show(window.klaroConfig);
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // eslint-disable-next-line no-console
        console.log('_JS_READY');
      },
    },

    appels_a_projet: {
      init: function() {
        jQuery(window).on('load', () => {
          const filters = {};

          function getComboFilter() {
            let combo = [];
            Object.values(filters).forEach(prop => {
              const group = prop;
              if (group.length) {
                // add first group
                if (combo.length) {
                  // add additional groups
                  const nextCombo = [];
                  // split group into combo: [ A, B ] & [ 1, 2 ] => [ A1, A2, B1, B2 ]
                  for (let i = 0; i < combo.length; i += 1) {
                    for (let j = 0; j < group.length; j += 1) {
                      const item = combo[i] + group[j];
                      nextCombo.push(item);
                    }
                  }
                  combo = nextCombo;
                } else {
                  combo = group.slice(0);
                }
              }
            });
            const comboFilter = combo.join(', ');
            return comboFilter;
          }

          // const $aapsGrid = $('.aaps-list');
          // // JavaScript to be fired on the home page
          // $aapsGrid.isotope({
          //   // options
          //   itemSelector: '.aap',
          //   // columnWidth: 200,
          //   layoutMode: 'vertical',
          //   hiddenStyle: {
          //     opacity: 0,
          //   },
          //   visibleStyle: {
          //     opacity: 1,
          //   },
          // });

          const aaplist = $('.aaps-list');
          if (aaplist.length) {
            const $aaplist = aaplist.isotope({
              itemSelector: '.aap',
              layoutMode: 'vertical',
              hiddenStyle: {
                opacity: 0,
              },
              visibleStyle: {
                opacity: 1,
              },
            });

            $('.aaps-filter').on('change', event => {
              const checkbox = event.target;
              const $checkbox = $(checkbox);
              const group = $checkbox.parents('.option-set').attr('data-group');
              // create array for filter group, if not there yet
              let filterGroup = filters[group];
              if (!filterGroup) {
                filters[group] = [];
                filterGroup = filters[group];
              }
              // add/remove filter
              if (checkbox.checked) {
                // add filter
                filterGroup.push(checkbox.value);
              } else {
                // remove filter
                const index = filterGroup.indexOf(checkbox.value);
                filterGroup.splice(index, 1);
              }

              $('.isotope-no-result').fadeOut('fast');
              $aaplist.isotope({filter: getComboFilter()});
            });

            // eslint-disable-next-line
            $aaplist.on('arrangeComplete', (event, filteredItems) => {
              if (filteredItems.length === 0) {
                $('.isotope-no-result').fadeIn('slow');
              }
            });
            $('.check-all').on('click', event => {
              event.preventDefault();
              $(event.target)
                .parents('.option-set')
                .find('input[type="checkbox"]')
                .prop('checked', true)
                .trigger('change');
              $('.isotope-no-result').fadeOut('fast');
              $aaplist.isotope({filter: getComboFilter()});
            });

            $('.uncheck-all').on('click', event => {
              event.preventDefault();
              $(event.target)
                .parents('.option-set')
                .find('input[type="checkbox"]')
                .prop('checked', false)
                .trigger('change');
              $('.isotope-no-result').fadeOut('fast');
              $aaplist.isotope({filter: getComboFilter()});
            });
          }
        });
      },
      finalize: function() {},
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      // eslint-disable-next-line no-param-reassign
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(
        i,
        classnm,
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
